import { RBAC_PERMISSIONS, RBAC_ROLES } from './constants'

export const convertPathIntoPermission = (pathname, search, user) => {
  const path = pathname.startsWith('/v2') ? pathname.replace('/v2', '') : pathname
  if (path.startsWith('/agreements/view')) {
    return RBAC_PERMISSIONS.VIEW_AGREEMENT
  } else if (path.startsWith('/agreements/edit')) {
    return RBAC_PERMISSIONS.UPDATE_AGREEMENT
  } else if (path.startsWith('/agreements/create')) {
    return RBAC_PERMISSIONS.ADD_AGREEMENT
  } else if (path.startsWith('/agreements')) {
    return RBAC_PERMISSIONS.AGREEMENTS
  } else if (path.startsWith('/grievances/view')) {
    return RBAC_PERMISSIONS.VIEW_GRIEVANCE
  } else if (path.startsWith('/grievances/edit')) {
    return RBAC_PERMISSIONS.UPDATE_GRIEVANCE
  } else if (path.startsWith('/grievances/create')) {
    return RBAC_PERMISSIONS.ADD_GRIEVANCE
  } else if (path.startsWith('/grievances')) {
    return RBAC_PERMISSIONS.GRIEVANCES
  } else if (path.startsWith('/general-managers/view')) {
    return RBAC_PERMISSIONS.VIEW_GENERAL_MANAGER
  } else if (path.startsWith('/general-managers/edit')) {
    return RBAC_PERMISSIONS.EDIT_GENERAL_MANAGERS
  } else if (path.startsWith('/general-managers/create')) {
    return RBAC_PERMISSIONS.ADD_GENERAL_MANAGER
  } else if (path.startsWith('/general-managers')) {
    return RBAC_PERMISSIONS.GENERAL_MANAGERS
  } else if (path.startsWith('/contacts/view')) {
    return RBAC_PERMISSIONS['VIEW/UPDATE_CONTACT']
  } else if (path.startsWith('/contacts/edit')) {
    return RBAC_PERMISSIONS['VIEW/UPDATE_CONTACT']
  } else if (path.startsWith('/contacts/create')) {
    return RBAC_PERMISSIONS.ADD_CONTACT
  } else if (path.startsWith('/contacts')) {
    return RBAC_PERMISSIONS.CONTACTS
  } else if (path.startsWith('/locations') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS['VIEW/UPDATE_LOCATION']
  } else if (path.startsWith('/locations') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS['VIEW/UPDATE_LOCATION']
  } else if (path.startsWith('/locations/create')) {
    return RBAC_PERMISSIONS.ADD_LOCATION
  } else if (path.startsWith('/locations/search')) {
    return RBAC_PERMISSIONS.LOCATIONS
  } else if (path.startsWith('/agreement-types') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS['VIEW/UPDATE_AGREEMENT_TYPE']
  } else if (path.startsWith('/agreement-types') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS['VIEW/UPDATE_AGREEMENT_TYPE']
  } else if (path.startsWith('/agreement-types/create')) {
    return RBAC_PERMISSIONS.ADD_AGREEMENT_TYPE
  } else if (path.startsWith('/agreement-types/search')) {
    return RBAC_PERMISSIONS.AGREEMENT_TYPES
  } else if (path.startsWith('/companies') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS['VIEW/UPDATE_COMPANY']
  } else if (path.startsWith('/companies') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS['VIEW/UPDATE_COMPANY']
  } else if (path.startsWith('/companies/create')) {
    return RBAC_PERMISSIONS.ADD_COMPANY
  } else if (path.startsWith('/companies/search')) {
    return RBAC_PERMISSIONS.COMPANIES
  } else if (path.startsWith('/contract-provisions')) {
    return RBAC_PERMISSIONS.CONTRACT_PROVISIONS
  } else if (path.startsWith('/contract-reports/agreements-ad-hoc')) {
    return RBAC_PERMISSIONS.AGREEMENTS_AD_HOC_REPORT
  } else if (path.startsWith('/contract-reports/companies-ad-hoc')) {
    return RBAC_PERMISSIONS.COMPANIES_AD_HOC_REPORT
  } else if (path.startsWith('/contract-reports/contacts-ad-hoc')) {
    return RBAC_PERMISSIONS.CONTACTS_AD_HOC_REPORT
  } else if (path.startsWith('/contract-reports/grievances-ad-hoc')) {
    return RBAC_PERMISSIONS.GRIEVANCES_AD_HOC_REPORT
  } else if (path.startsWith('/contract-reports/agreements-mailing')) {
    return RBAC_PERMISSIONS.AGREEMENTS_MAILING
  } else if (path.startsWith('/contract-reports/expiring-contracts-ad-hoc')) {
    return RBAC_PERMISSIONS.EXPIRING_CONTRACTS_AD_HOC_REPORT
  } else if (path.startsWith('/contract-reports/active-productions-list')) {
    return RBAC_PERMISSIONS.ACTIVE_PRODUCTIONS_LIST
  } else if (path.startsWith('/contract-reports/weekly-mailing')) {
    return RBAC_PERMISSIONS.WEEKLY_MAILING
  } else if (path.startsWith('/forms-and-booklets/search')) {
    return RBAC_PERMISSIONS.FORMS_AND_BOOKLETS
  } else if (path.startsWith('/forms-and-booklets/create')) {
    return RBAC_PERMISSIONS.ORDER_FORMS_AND_BOOKLETS
  } else if (path.startsWith('/local-admin-address-upload')) {
    return RBAC_PERMISSIONS.ADDRESS_UPLOAD
  } else if (path.startsWith('/reports/local-membership-roster')) {
    return RBAC_PERMISSIONS.LOCAL_MEMBERSHIP_ROSTER
  } else if (path.startsWith('/reports/report-stamp-purchase-qr-requirement')) {
    return RBAC_PERMISSIONS.STAMP_PURCHASE_QR_REQUIREMENTS
  } else if (path.startsWith('/reports/ttf-titles-adHoc')) {
    return RBAC_PERMISSIONS.TTF_TITLES_AD_HOC_REPORT
  } else if (path.startsWith('/reports/ttf-member-status')) {
    return RBAC_PERMISSIONS.TTF_MEMBER_STATUS_REPORT
  } else if (path.startsWith('/reports/il-number-search')) {
    return RBAC_PERMISSIONS.IL_NUMBER_SEARCH_REPORT
  } else if (path.startsWith('/others/comps') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.VIEW_COMPS_MEMBER
  } else if (path.startsWith('/others/comps') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.UPDATE_COMPS_MEMBER
  } else if (path.startsWith('/others/comps/create')) {
    return RBAC_PERMISSIONS.ADD
  } else if (path.startsWith('/others/comps/search')) {
    return RBAC_PERMISSIONS.COMPS_MEMBERS
  } else if (path.startsWith('/others/usps/upload')) {
    return RBAC_PERMISSIONS.UPLOAD_USPS_FILE
  } else if (path.startsWith('/others/usps/process')) {
    return RBAC_PERMISSIONS.PROCESS_USPS_FILE
  } else if (path.startsWith('/template-management/view')) {
    return RBAC_PERMISSIONS.VIEW_TEMPLATE
  } else if (path.startsWith('/template-management/edit')) {
    return RBAC_PERMISSIONS.VIEW_TEMPLATE
  } else if (path.startsWith('/template-management/create')) {
    return RBAC_PERMISSIONS.ADD_TEMPLATE
  } else if (path.startsWith('/template-management')) {
    return RBAC_PERMISSIONS.TEMPLATE_MGMT
  } else if (path.startsWith('/venues/view')) {
    return RBAC_PERMISSIONS.VIEW_VENUE
  } else if (path.startsWith('/venues/edit')) {
    return RBAC_PERMISSIONS.UPDATE_VENUE
  } else if (path.startsWith('/venues/create')) {
    return RBAC_PERMISSIONS.ADD_VENUE
  } else if (path.startsWith('/venues')) {
    return RBAC_PERMISSIONS.VENUES
  } else if (path.startsWith('/search-contracts')) {
    return RBAC_PERMISSIONS.CONTRACTS
  } else if (path.startsWith('/ttf-titles') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.VIEW_TTF_TITLE
  } else if (path.startsWith('/ttf-titles') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.EDIT_TTF_TITLE
  } else if (path.startsWith('/ttf-titles/create')) {
    return RBAC_PERMISSIONS.ADD_TTF_TITLE
  } else if (path.startsWith('/ttf-titles/upload')) {
    return RBAC_PERMISSIONS.UPLOAD_TTF_FILES
  } else if (path.startsWith('/ttf-titles/lists')) {
    return RBAC_PERMISSIONS.EDIT_TTF_TITLE
  } else if (path.startsWith('/ttf-titles/search')) {
    return RBAC_PERMISSIONS.TTF_TITLES
  } else if (path.startsWith('/ttf-contacts') && path.endsWith('/contact-ttf-titles/view')) {
    return RBAC_PERMISSIONS.CONTACT_TTF_TITLES
  } else if (path.startsWith('/ttf-contacts') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.VIEW_TTF_CONTACT
  } else if (path.startsWith('/ttf-contacts') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.UPDATE_TTF_CONTACT
  } else if (path.startsWith('/ttf-contacts/new')) {
    return RBAC_PERMISSIONS.ADD_TTF_CONTACT
  } else if (path.startsWith('/ttf-contacts/search')) {
    return RBAC_PERMISSIONS.TTF_CONTACTS
  } else if (path.startsWith('/ttf-contacts/primary-job-classifications')) {
    return RBAC_PERMISSIONS.PRIMARY_JOB_CLASSIFICATIONS
  } else if (path.startsWith('/ttf-contacts/migrate')) {
    return RBAC_PERMISSIONS.MIGRATE_TTF_CONTACTS
  } else if (path.startsWith('/member-events') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.MEMBERS_VIEW_EVENTS
  } else if (path.startsWith('/member-events') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.MEMBERS_EDIT_EVENTS
  } else if (path.startsWith('/member-events/create')) {
    return RBAC_PERMISSIONS.ADD_MEMBER_EVENT
  } else if (path.startsWith('/member-events/search')) {
    return RBAC_PERMISSIONS.MEMBERS_EVENTS
  } else if (path.startsWith('/safety-app/main-menu-items')) {
    return RBAC_PERMISSIONS.MAIN_MENU_ITEMS
  } else if (path.startsWith('/safety-app/sub-menu-items')) {
    return RBAC_PERMISSIONS.SUB_MENU_ITEMS
  } else if (path.startsWith('/safety-app/content')) {
    return RBAC_PERMISSIONS.CONTENT
  } else if (path.startsWith('/safety-app/safety-numbers')) {
    return RBAC_PERMISSIONS.SAFETY_NUMBERS
  } else if (path.startsWith('/safety-app/emails')) {
    return RBAC_PERMISSIONS.EMAILS
  } else if (path.startsWith('/safety-app/submitted-hazard-forms')) {
    return RBAC_PERMISSIONS.SUBMITTED_HAZARD_FORMS
  } else if (path.startsWith('/audit-log')) {
    return RBAC_PERMISSIONS.AUDIT_LOG
  } else if (path.startsWith('/admin/roles') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.MODIFY_ROLE
  } else if (path.startsWith('/admin/roles') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.MODIFY_ROLE
  } else if (path.startsWith('/admin/roles/create')) {
    return RBAC_PERMISSIONS.CREATE_ROLE
  } else if (path.startsWith('/admin/roles/search')) {
    return RBAC_PERMISSIONS.MODIFY_ROLE
  } else if (path.startsWith('/admin/users') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.UPDATE_USER_INFO
  } else if (path.startsWith('/admin/users') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.UPDATE_USER_INFO
  } else if (path.startsWith('/admin/users/search')) {
    return RBAC_PERMISSIONS.UPDATE_USER_INFO
  } else if (path.startsWith('/admin/users/create')) {
    return RBAC_PERMISSIONS.CREATE_USER
  } else if (path.startsWith('/admin/impersonate-admin')) {
    return RBAC_PERMISSIONS.IMPERSONATE_ADMIN
  } else if (path.startsWith('/admin/users/unlock')) {
    return RBAC_PERMISSIONS.UNLOCK_USER
  } else if (path.startsWith('/admin/users/revoke')) {
    return RBAC_PERMISSIONS.REVOKE_USER
  } else if (path.startsWith('/admin/users/reset')) {
    return RBAC_PERMISSIONS.RESET_USER
  } else if (path.startsWith('/locals') && path.includes('/officer-maintenance/update/officer')) {
    return user.appRoles.includes(RBAC_ROLES.LOCAL_ADMIN)
      ? RBAC_PERMISSIONS.LOCAL_INFO
      : RBAC_PERMISSIONS.UPDATE_OFFICER
  } else if (path.startsWith('/locals') && path.endsWith('/officer-maintenance/view')) {
    return user.appRoles.includes(RBAC_ROLES.LOCAL_ADMIN)
      ? RBAC_PERMISSIONS.LOCAL_INFO
      : RBAC_PERMISSIONS.OFFICER_MAINTENANCE
  } else if (path.startsWith('/locals') && path.endsWith('/officer-maintenance/create')) {
    return user.appRoles.includes(RBAC_ROLES.LOCAL_ADMIN)
      ? RBAC_PERMISSIONS.LOCAL_INFO
      : RBAC_PERMISSIONS.OFFICER_MAINTENANCE
  } else if (path.startsWith('/locals') && path.endsWith('/officer-history/view')) {
    return RBAC_PERMISSIONS.OFFICERS_HISTORY
  } else if (path.startsWith('/locals') && path.endsWith('/committee/view')) {
    return RBAC_PERMISSIONS.CANADIAN_COMMITTEES
  } else if (path.startsWith('/locals') && path.endsWith('/view')) {
    return user.appRoles.includes(RBAC_ROLES.LOCAL_ADMIN) ? RBAC_PERMISSIONS.LOCAL_INFO : RBAC_PERMISSIONS.VIEW_LOCAL
  } else if (path.startsWith('/locals') && path.endsWith('/edit')) {
    return user.appRoles.includes(RBAC_ROLES.LOCAL_ADMIN) ? RBAC_PERMISSIONS.LOCAL_INFO : RBAC_PERMISSIONS.UPDATE_LOCAL
  } else if (path.startsWith('/locals/create')) {
    return RBAC_PERMISSIONS.NEW_LOCAL
  } else if (path.startsWith('/locals/search')) {
    return RBAC_PERMISSIONS.LOCALS
  } else if (path.startsWith('/locals/committee-report')) {
    return RBAC_PERMISSIONS.LOCALS_COMMITEE_REPORT
  } else if (path.startsWith('/locals/committee-report')) {
    return RBAC_PERMISSIONS.LOCALS_COMMITEE_REPORT
  } else if (path.startsWith('/locals/supplies-sent-maintenance')) {
    return RBAC_PERMISSIONS.LOCALS_SUPPLIES_SENT_MAINTENANCE
  } else if (path.startsWith('/locals/merge-locals')) {
    return RBAC_PERMISSIONS.MERGE_LOCALS
  } else if (path.startsWith('/committees')) {
    return RBAC_PERMISSIONS.CANADIAN_COMMITTEES
  } else if (path.startsWith('/member-card-maintenance') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.MEMBER_CARD_VIEW
  } else if (path.startsWith('/member-card-maintenance') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.MEMBER_CARD_EDIT
  } else if (path.startsWith('/member-card-maintenance/create')) {
    return RBAC_PERMISSIONS.MEMBER_CARDS_ADD_RANGE
  } else if (path.startsWith('/member-card-maintenance/search')) {
    return RBAC_PERMISSIONS.MEMBER_CARD_MAINT
  } else if (path.startsWith('/members') && path.endsWith('/member-transfer/view')) {
    return RBAC_PERMISSIONS.MEMBERS_TRANSFER
  } else if (path.startsWith('/members') && path.endsWith('/contract-history/view')) {
    return RBAC_PERMISSIONS.MEMBERS_CONTRACT_HISTORY
  } else if (path.startsWith('/members') && path.endsWith('/audit-log-report/view')) {
    return RBAC_PERMISSIONS.MEMBERS_AUDIT_LOG_REPORT
  } else if (path.startsWith('/members') && path.endsWith('/member-status-history/view')) {
    return RBAC_PERMISSIONS.MEMBERS_STATUS_HISTORY
  } else if (path.startsWith('/members') && path.endsWith('/local-admit-history/view')) {
    return RBAC_PERMISSIONS.MEMBERS_LOCAL_ADMIT_HISTORY
  } else if (path.startsWith('/members') && path.endsWith('/member-ttf-titles/view')) {
    return RBAC_PERMISSIONS.MEMBERS_TTF_TITLES
  } else if (path.startsWith('/members') && path.endsWith('/member-events/view')) {
    return RBAC_PERMISSIONS.MEMBERS_EVENTS
  } else if (path.startsWith('/members') && path.endsWith('/member-actions/view')) {
    return RBAC_PERMISSIONS.MEMBERS_ACTIONS
  } else if (path.startsWith('/members') && path.endsWith('/retired-application/view')) {
    return RBAC_PERMISSIONS.MEMBERS_UPDATE
  } else if (path.startsWith('/members') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.MEMBERS_VIEW
  } else if (path.startsWith('/members') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.MEMBERS_UPDATE
  } else if (path.startsWith('/members/create')) {
    return RBAC_PERMISSIONS.MEMBERS_CREATE
  } else if (path.startsWith('/members/search')) {
    return RBAC_PERMISSIONS.MEMBERS
  } else if (path.startsWith('/quarterly-reports')) {
    return RBAC_PERMISSIONS.QUARTERLY_REPORTS
  } else if (path.startsWith('/orders/search')) {
    return RBAC_PERMISSIONS.ORDERS
  } else if (path.startsWith('/orders/special-order')) {
    return RBAC_PERMISSIONS.ORDERS_CREATE
  } else if (path.startsWith('/orders/create-order')) {
    return RBAC_PERMISSIONS.ORDERS_CREATE
  } else if (path.startsWith('/orders/view-order') && search.includes('allocate_stamps')) {
    return RBAC_PERMISSIONS.ALLOCATE_REPLACEMENT_STAMPS
  } else if (path.startsWith('/orders/order-paid')) {
    return RBAC_PERMISSIONS.ORDERS_VIEW
  } else if (path.startsWith('/orders/view-order') || path.startsWith('/orders/transaction/view-order')) {
    return RBAC_PERMISSIONS.ORDERS_VIEW
  } else if (path.startsWith('/orders/add-order')) {
    return RBAC_PERMISSIONS.ORDERS_CREATE
  } else if (path.startsWith('/orders/edit-order') || path.startsWith('/orders/transaction/edit-order')) {
    return RBAC_PERMISSIONS.ORDERS_UPDATE
  } else if (path.startsWith('/orders/items') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.ORDERS_VIEW_ITEM
  } else if (path.startsWith('/orders/items') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.ORDERS_UPDATE_ITEM
  } else if (path.startsWith('/orders/items/search')) {
    return RBAC_PERMISSIONS.ORDERS_ITEMS
  } else if (path.startsWith('/orders/items/stamp-item/create')) {
    return RBAC_PERMISSIONS.ORDERS_ADD_STAMP_ITEM
  } else if (path.startsWith('/orders/items/create')) {
    return RBAC_PERMISSIONS.ORDERS_ADD_ITEM
  } else if (path.startsWith('/orders/items/local-craft') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.ORDERS_LOCAL_CRAFTS
  } else if (path.startsWith('/orders/items/local-craft') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.ORDERS_LOCAL_CRAFTS
  } else if (path.startsWith('/orders/items/local-crafts/create')) {
    return RBAC_PERMISSIONS.ORDERS_ADD_LOCAL_CRAFTS
  } else if (path.startsWith('/orders/items/local-crafts/search')) {
    return RBAC_PERMISSIONS.ORDERS_LOCAL_CRAFTS
  } else if (path.startsWith('/orders/stamp') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.ORDERS_UPDATE_STAMP_ITEM
  } else if (path.startsWith('/orders/stamps')) {
    return RBAC_PERMISSIONS.ORDERS_VIEW_STAMP_ITEM
  } else if (path.startsWith('/orders/transaction')) {
    return RBAC_PERMISSIONS.TRANSACTION_CHECK
  } else if (path.startsWith('/orders/thank-you')) {
    return RBAC_PERMISSIONS.ORDERS
  } else if (path.startsWith('/shows') && path.includes('/pink-contracts') && path.endsWith('/replacement')) {
    return RBAC_PERMISSIONS.PINK_CONTRACT_PERMANENT_SUBSTITUTE_CONTRACT
  } else if (path.startsWith('/shows') && path.includes('/pink-contracts') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.PINK_CONTRACT_VIEW_UPDATE
  } else if (path.startsWith('/shows') && path.includes('/pink-contracts') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.PINK_CONTRACT_VIEW_UPDATE
  } else if (path.startsWith('/shows') && path.endsWith('/pink-contracts/search')) {
    return RBAC_PERMISSIONS.PINK_CONTRACT_LIST
  } else if (path.startsWith('/shows') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.SHOWS_VIEW
  } else if (path.startsWith('/shows') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.SHOWS_UPDATE
  } else if (path.startsWith('/shows/create')) {
    return RBAC_PERMISSIONS.SHOWS_CREATE
  } else if (path.startsWith('/shows/search')) {
    return RBAC_PERMISSIONS.SHOWS
  } else if (path.startsWith('/shows/pink-contract-positions') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.PINK_CONTRACT_POSITIONS
  } else if (path.startsWith('/shows/pink-contract-positions') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.PINK_CONTRACT_POSITIONS
  } else if (path.startsWith('/shows/pink-contract-positions/create')) {
    return RBAC_PERMISSIONS.PINK_CONTRACT_POSITIONS_CREATE
  } else if (path.startsWith('/shows/pink-contract-positions/search')) {
    return RBAC_PERMISSIONS.PINK_CONTRACT_POSITIONS
  } else if (path.startsWith('/members/membership-applications')) {
    return RBAC_PERMISSIONS.MEMBERSHIP_APPLICATION
  } else if (path.startsWith('/members/membership-requests')) {
    return RBAC_PERMISSIONS.MEMBERSHIP_REQUEST
  } else if (path.startsWith('/members/member-group-maintenance')) {
    return RBAC_PERMISSIONS.MEMBER_GROUP_MAINTENANCE
  } else if (path.startsWith('/members/multiple-member-search')) {
    return RBAC_PERMISSIONS.MULTIPLE_MEMBER_SEARCH
  } else if (path.startsWith('/admin/member-address-file')) {
    return RBAC_PERMISSIONS.MEMBER_ADDRESS_FILE
  } else if (path.startsWith('/convention/search')) {
    return RBAC_PERMISSIONS.CONVENTION
  } else if (path.startsWith('/convention/create')) {
    return RBAC_PERMISSIONS.CONVENTION
  } else if (path.startsWith('/convention') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.VIEW_DELEGATE
  } else if (path.startsWith('/convention') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.UPDATE_DELEGATE
  } else if (path.startsWith('/convention/delegate-and-vote-stats')) {
    return RBAC_PERMISSIONS['DELEGATE_&_VOTE_STATS']
  } else if (path.startsWith('/convention/delegates-by-local')) {
    return RBAC_PERMISSIONS.DELEGATES_BY_LOCAL
  } else if (path.startsWith('/convention/delegates-by-district')) {
    return RBAC_PERMISSIONS.DELEGATES_BY_DISTRICT
  } else if (path.startsWith('/convention/charges-to-master-account')) {
    return RBAC_PERMISSIONS.CHARGES_TO_MASTER_ACCOUNT
  } else if (path.startsWith('/convention/travel-reimbursements')) {
    return RBAC_PERMISSIONS.TRAVEL_REIMBURSEMENTS
  } else if (path.startsWith('/convention/travel-expense-summary')) {
    return RBAC_PERMISSIONS.TRAVEL_EXPENSE_SUMMARY
  } else if (path.startsWith('/convention/print-delegate-badges')) {
    return RBAC_PERMISSIONS.PRINT_DELEGATE_BADGES
  } else if (path.startsWith('/convention/delegate-checks')) {
    return RBAC_PERMISSIONS.DELEGATE_CHECKS
  } else if (path.startsWith('/convention/delegate-wno-travel-expenses')) {
    return RBAC_PERMISSIONS['DELEGATES_W/_NO_TRAVEL_EXPENSES']
  } else if (path.startsWith('/convention/set-convention-per-diem')) {
    return RBAC_PERMISSIONS.SET_CONVENTION_PER_DIEM
  } else if (path.startsWith('/convention/proofread-short-jurisdiction')) {
    return RBAC_PERMISSIONS.PROOFREAD_SHORT_JURISDICTIONS
  } else if (path.startsWith('/convention/proofread-max-delegates-and-votes')) {
    return RBAC_PERMISSIONS['PROOFREAD_MAX_DELEGATES_&_VOTES']
  } else if (path.startsWith('/convention/committee-maintenance')) {
    return RBAC_PERMISSIONS.COMMITTEE_MAINTENANCE
  } else if (path.startsWith('/convention/committee-maintenance/committees-report')) {
    return RBAC_PERMISSIONS.COMMITTEE_MAINTENANCE
  } else if (path.startsWith('/convention/committee-maintenance/pending-committees-report')) {
    return RBAC_PERMISSIONS.COMMITTEE_MAINTENANCE
  } else if (path.startsWith('/convention/review-delegate-requests')) {
    return RBAC_PERMISSIONS.REVIEW_DELEGATE_REQUESTS
  } else if (path.startsWith('/convention/review-delegate-requests/resend-emails')) {
    return RBAC_PERMISSIONS.REVIEW_DELEGATE_REQUESTS
  } else if (path.startsWith('/visa-tracking/search')) {
    return RBAC_PERMISSIONS.VISA_TRACKING
  } else if (path.startsWith('/visa-tracking/create')) {
    return RBAC_PERMISSIONS.ADD_VISA_REQUEST
  } else if (path.startsWith('/visa-tracking') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.VISA_TRACKING
  } else if (path.startsWith('/visa-tracking') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.EDIT_VISA_REQUEST
  } else if (path.startsWith('/visa-tracking/beneficiaries/search')) {
    return RBAC_PERMISSIONS.MANAGE_BENEFICIARIES
  } else if (path.startsWith('/visa-tracking/beneficiaries/create')) {
    return RBAC_PERMISSIONS.ADD_BENEFICIARY
  } else if (path.startsWith('/visa-tracking/beneficiaries') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.MANAGE_BENEFICIARIES
  } else if (path.startsWith('/visa-tracking/beneficiaries') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.EDIT_BENEFICIARY
  } else if (path.startsWith('/visa-tracking/filing-agencies/search')) {
    return RBAC_PERMISSIONS.MANAGE_FILING_AGENCIES
  } else if (path.startsWith('/visa-tracking/filing-agencies/create')) {
    return RBAC_PERMISSIONS.ADD_FILING_AGENCY
  } else if (path.startsWith('/visa-tracking/filing-agencies') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.MANAGE_FILING_AGENCIES
  } else if (path.startsWith('/visa-tracking/filing-agencies') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.EDIT_FILING_AGENCY
  } else if (path.startsWith('/visa-tracking/petitioners/search')) {
    return RBAC_PERMISSIONS.MANAGE_PETITIONERS
  } else if (path.startsWith('/visa-tracking/petitioners/create')) {
    return RBAC_PERMISSIONS.ADD_PETITIONER
  } else if (path.startsWith('/visa-tracking/petitioners') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.MANAGE_PETITIONERS
  } else if (path.startsWith('/visa-tracking/petitioners') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.EDIT_PETITIONER
  } else if (path.startsWith('/visa-tracking/ins-agencies/search')) {
    return RBAC_PERMISSIONS.MANAGE_INS_AGENCIES
  } else if (path.startsWith('/visa-tracking/ins-agencies/create')) {
    return RBAC_PERMISSIONS.ADD_INS_AGENCY
  } else if (path.startsWith('/visa-tracking/ins-agencies') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.MANAGE_INS_AGENCIES
  } else if (path.startsWith('/visa-tracking/ins-agencies') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.EDIT_INS_AGENCY
  } else if (path.startsWith('/visa-tracking/engagements/search')) {
    return RBAC_PERMISSIONS.MANAGE_ENGAGEMENTS
  } else if (path.startsWith('/visa-tracking/engagements/create')) {
    return RBAC_PERMISSIONS.ADD_ENGAGEMENT
  } else if (path.startsWith('/visa-tracking/engagements') && path.endsWith('/view')) {
    return RBAC_PERMISSIONS.MANAGE_ENGAGEMENTS
  } else if (path.startsWith('/visa-tracking/engagements') && path.endsWith('/edit')) {
    return RBAC_PERMISSIONS.EDIT_ENGAGEMENT
  } else if (path.startsWith('/visa-tracking/visa-engagement-report')) {
    return RBAC_PERMISSIONS.VISA_BY_ENGAGEMENT_REPORT
  } else if (path.startsWith('/event-contacts')) {
    return RBAC_PERMISSIONS.EVENT_CONTACTS
  } else if (path.startsWith('/event-contacts/view')) {
    return RBAC_PERMISSIONS.EVENT_CONTACTS
  } else if (path.startsWith('/event-contacts/edit')) {
    return RBAC_PERMISSIONS.EVENT_CONTACTS
  } else if (path.startsWith('/event-contacts/create')) {
    return RBAC_PERMISSIONS.EVENT_CONTACTS
  } else if (path.startsWith('/event-contacts/') && path.endsWith('event-contact-events')) {
    return RBAC_PERMISSIONS.EVENT_CONTACTS
  } else if (path.startsWith('/organizing') && path.includes('/send-bulk-email')) {
    return RBAC_PERMISSIONS.SEND_BULK_EMAIL
  } else if (path.startsWith('/organizing') && path.includes('/import-crew-list')) {
    return RBAC_PERMISSIONS.IMPORT_CREW_LIST
  } else if (path.startsWith('/organizing') && path.includes('/artist/create')) {
    return RBAC_PERMISSIONS.ADD_ARTIST
  } else if (path.startsWith('/organizing') && path.includes('/artist/view')) {
    return RBAC_PERMISSIONS['VIEW/EDIT_ARTIST']
  } else if (path.startsWith('/organizing') && path.includes('/artist/edit')) {
    return RBAC_PERMISSIONS['VIEW/EDIT_ARTIST']
  } else if (path.startsWith('/organizing') && path.includes('/submission/create')) {
    return RBAC_PERMISSIONS.SUBMIT_MANUAL_ORGANIZING_CARD
  } else if (path.startsWith('/organizing/view')) {
    return RBAC_PERMISSIONS.VIEW_ORGANIZING_CAMPAIGN
  } else if (path.startsWith('/organizing/edit')) {
    return RBAC_PERMISSIONS.VIEW_ORGANIZING_CAMPAIGN
  } else if (path.startsWith('/organizing/create')) {
    return RBAC_PERMISSIONS.ADD_ORGANIZING_CAMPAIGN
  } else if (path.startsWith('/organizing')) {
    return RBAC_PERMISSIONS.ORGANIZING
  } else if (path.startsWith('/traveling-members-reports/traveling-members-by-local')) {
    return RBAC_PERMISSIONS.TRAVELING_MEMBERS_BY_LOCAL
  } else if (path.startsWith('traveling-members-reports/traveling-members-contract-history')) {
    return RBAC_PERMISSIONS.TRAVELING_MEMBERS_CONTRACT_HISTORY
  } else if (path.startsWith('traveling-members-reports/traveling-members-by-craft')) {
    return RBAC_PERMISSIONS.TRAVELING_MEMBERS_BY_CRAFT
  } else if (path.startsWith('traveling-members-reports/traveling-members-by-department')) {
    return RBAC_PERMISSIONS.TRAVELING_MEMBERS_BY_DEPARTMENT
  } else if (path.startsWith('traveling-members-reports/show-list-by-status')) {
    return RBAC_PERMISSIONS.SHOW_LIST_BY_STATUS
  } else if (path.startsWith('traveling-members-reports/type-of-show')) {
    return RBAC_PERMISSIONS.TYPE_OF_SHOW
  } else if (path.startsWith('traveling-members-reports/type-of-salary')) {
    return RBAC_PERMISSIONS.TYPE_OF_SALARY
  } else if (path.startsWith('traveling-members-reports/local-report')) {
    return RBAC_PERMISSIONS.LOCAL_REPORT
  } else if (path.startsWith('traveling-members-reports/pink-contract-report')) {
    return RBAC_PERMISSIONS.PINK_CONTRACT_REPORT
  } else if (path.startsWith('/search')) {
    return RBAC_PERMISSIONS.GENERAL_SEARCH
  } else if (path.startsWith('/user-settings/change-email')) {
    return RBAC_PERMISSIONS.CHANGE_EMAIL
  } else if (path.startsWith('/user-settings/change-password')) {
    return RBAC_PERMISSIONS.CHANGE_PASSWORD
  } else if (path.startsWith('/reports/landing-page')) {
    return RBAC_PERMISSIONS.REPORTS
  } else if (path.startsWith('/reports/member-events-ad-hoc-report')) {
    return RBAC_PERMISSIONS.MEMBER_EVENTS_AD_HOC_REPORT
  } else if (path.startsWith('/reports/daily-check-register')) {
    return RBAC_PERMISSIONS.DAILY_CHECK_REGISTER
  } else if (path.startsWith('/reports/summary-cash-register-by-day')) {
    return RBAC_PERMISSIONS.SUMMARY_CASH_REGISTER_BY_DAY
  } else if (path.startsWith('/reports/summary-cash-register-by-month')) {
    return RBAC_PERMISSIONS.SUMMARY_CASH_REGISTER_BY_MONTH
  } else if (path.startsWith('/reports/locals-list-of-checks')) {
    return RBAC_PERMISSIONS.LOCALS_LIST_OF_CHECKS
  } else if (path.startsWith('/reports/cash-deposit')) {
    return RBAC_PERMISSIONS.CASH_DEPOSIT
  } else if (path.startsWith('/reports/transaction-report')) {
    return RBAC_PERMISSIONS.TRANSACTION_REPORT
  } else if (path.startsWith('/reports/quarterly-report')) {
    return RBAC_PERMISSIONS.QUARTERLY_REPORT
  } else if (path.startsWith('/reports/scrolls-report')) {
    return RBAC_PERMISSIONS.SCROLLS_REPORT
  } else if (path.startsWith('/reports/stamp-purchase-tracking-info')) {
    return RBAC_PERMISSIONS.STAMP_PURCHASE_TRACKING_INFO
  } else if (path.startsWith('/reports/stamp-purchase-tracking-by-local')) {
    return RBAC_PERMISSIONS.STAMP_PURCHASE_TRACKING_BY_LOCAL
  } else if (path.startsWith('/reports/member-by-district')) {
    return RBAC_PERMISSIONS.MEMBER_BY_DISTRICT
  } else if (path.startsWith('/reports/local-crafts-by-district')) {
    return RBAC_PERMISSIONS.LOCAL_CRAFTS_BY_DISTRICT
  } else if (path.startsWith('/reports/local-jurisdiction-by-district')) {
    return RBAC_PERMISSIONS.LOCAL_JURISDICTION_BY_DISTRICT
  } else if (path.startsWith('/reports/local-membership-by-craft')) {
    return RBAC_PERMISSIONS.LOCAL_MEMBERSHIP_BY_CRAFT
  } else if (path.startsWith('/reports/local-officers')) {
    return RBAC_PERMISSIONS.LOCAL_OFFICERS
  } else if (path.startsWith('/reports/local-year-end-supplies')) {
    return RBAC_PERMISSIONS.LOCAL_YEAR_END_SUPPLIES
  } else if (path.startsWith('/reports/stamp-purchase-qr-info')) {
    return RBAC_PERMISSIONS.STAMP_PURCHASE_AND_QR_INFO
  } else if (path.startsWith('/reports/report-stamp-purchase-qr-requirement')) {
    return RBAC_PERMISSIONS.STAMP_PURCHASE_AND_QR_REQUIREMENTS
  } else if (path.startsWith('/reports/deceased-members')) {
    return RBAC_PERMISSIONS.DECEASED_MEMBERS
  } else if (path.startsWith('/reports/locals-delinquent-in-stamps')) {
    return RBAC_PERMISSIONS.LOCALS_DELINQUENT_IN_STAMPS
  } else if (path.startsWith('/reports/local-officers-mailing-labels')) {
    return RBAC_PERMISSIONS.LOCAL_OFFICERS_MAILING_LABELS
  } else if (path.startsWith('/reports/locals-with-outstanding-qrs')) {
    return RBAC_PERMISSIONS.LOCALS_WITH_OUTSTANDING_QRS
  } else if (path.startsWith('/locals-eligible-for-delegate-kit')) {
    return RBAC_PERMISSIONS.LOCALS_ELIGIBLE_FOR_DELEGATE_KIT
  } else if (path.startsWith('/reports/unpublished-show-report')) {
    return RBAC_PERMISSIONS.UNPUBLISHED_SHOW_REPORT
  } else if (path.startsWith('/reports/quarterly-report-warning-letter')) {
    return RBAC_PERMISSIONS.QUARTERLY_REPORT_WARNING_LETTER
  } else if (path.startsWith('/reports/year-end-supplies-letter')) {
    return RBAC_PERMISSIONS.YEAR_END_SUPPLIES_LETTER
  } else if (path.startsWith('/reports/qrs-submitted-online')) {
    return RBAC_PERMISSIONS.QRS_SUBMITTED_ONLINE
  } else if (path.startsWith('/reports/officer-term-end-report')) {
    return RBAC_PERMISSIONS.OFFICER_TERM_END_REPORT
  } else if (path.startsWith('/reports/member-actions-ad-hoc-report')) {
    return RBAC_PERMISSIONS.MEMBER_ACTIONS_AD_HOC_REPORT
  } else if (path.startsWith('/reports/per-capita-waiver-orders-report')) {
    return RBAC_PERMISSIONS.PER_CAPITA_WAIVER_ORDERS_REPORT
  } else if (path.startsWith('/reports/daily-transaction-report')) {
    return RBAC_PERMISSIONS.DAILY_TRANSACTION_REPORT
  }
}
